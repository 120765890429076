import 'core-js/es/map';
import 'core-js/es/set';

import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/get-own-property-descriptor';
import 'core-js/features/object/get-own-property-descriptors';
import 'core-js/features/object/keys';
import 'core-js/features/object/values';

import 'core-js/features/string/ends-with';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/includes';

import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/of';

import 'core-js/features/number/is-finite';

import 'core-js/features/symbol/for';
